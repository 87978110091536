export default function PriceInfoTable({tariffeMacellazione}: {tariffeMacellazione: {nome: string, quantita: Record<number, {codice: string, tariffa: number}>}}) {
    return(
        <table className="table table-bordered table-striped rounded mt-3 overflow-hidden">
                                    <thead>
                                    <tr>
                                        <th className="bg-grey text-white">Numero {tariffeMacellazione.nome.slice(0,-1)}I</th>
                                        <th className="bg-grey text-white">Codice e Quantità</th>
                                        <th className="bg-grey text-white">Totale (€)</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {Object.entries(tariffeMacellazione.quantita).map(([key, value]) => (
                                            <tr key={key}>
                                                <td>{`${key} ${key == "1" ? tariffeMacellazione.nome : tariffeMacellazione.nome.slice(0,-1) + "I"}`}</td>
                                                <td>{value.codice}</td>
                                                <td>{value.tariffa}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
    )
}