import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useGetComuniQuery, useGetPrestazioniQuery } from "../services/apiSlice";
import { format } from "date-fns";

interface EventDetailsModalProps {
    event: any;
    show: boolean;
    onHide: () => void;
}

const EventDetailsModal = ({ event, show, onHide }: EventDetailsModalProps) => {
    // Otteniamo i dati dei comuni
    const { data: comuniData } = useGetComuniQuery(null);
    
    // Otteniamo i dati delle prestazioni
    const { data: prestazioniData } = useGetPrestazioniQuery({
        reddito: true,
        tipo: ""
    });
    
    // Funzione per ottenere il nome del comune dall'ID
    const getComuneNome = (comuneId: string) => {
        if (!comuniData) return comuneId;
        const comune = comuniData.find(c => c.id === comuneId);
        return comune ? comune.descrizione : comuneId;
    };
    
    // Funzione per ottenere la descrizione della prestazione dall'ID
    const getPrestazioneDescrizione = (prestazioneId: string) => {
        if (!prestazioniData) return prestazioneId;
        const prestazione = prestazioniData.find(p => p.id === prestazioneId);
        return prestazione ? prestazione.descrizione : prestazioneId;
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Dettagli Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {event && (
                    <>
                        <div className="mb-3">
                            <strong>Tipo prestazione:</strong> {event.tipo_prestazione ? 
                                getPrestazioneDescrizione(event.tipo_prestazione) : 
                                'Non specificata'}
                        </div>
                        <div className="mb-3">
                            <strong>Data:</strong> {format(new Date(event.booking_date_time_start), 'dd/MM/yyyy')}
                        </div>
                        <div className="mb-3">
                            <strong>Orario:</strong> {format(new Date(event.booking_date_time_start), 'HH:mm')} - 
                            {format(new Date(event.booking_date_time_end), 'HH:mm')}
                        </div>
                        {event.comune && (
                            <div className="mb-3">
                                <strong>Comune:</strong> {getComuneNome(event.comune)}
                            </div>
                        )}
                        {event.cod_azienda && (
                            <div className="mb-3">
                                <strong>Codice Azienda:</strong> {event.cod_azienda} - {event.sottocod_azienda}
                            </div>
                        )}
                        {event.note && (
                            <div className="mb-3">
                                <strong>Note:</strong> {event.note}
                            </div>
                        )}
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Chiudi
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default EventDetailsModal; 