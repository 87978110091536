import React, {useEffect, useRef, useState, useCallback} from "react";
import Form from "react-bootstrap/Form";
import {Formik} from "formik";
import {Button} from "react-bootstrap";
import FormSelect from "./FormSelect";
import 'leaflet/dist/leaflet.css';
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import 'leaflet/dist/leaflet.css';
import L, {LatLngExpression} from 'leaflet';
import { Map as LeafletMap } from 'leaflet';
import {convertStringToNumberArray} from "../assets/utilities/utilities";
import {
    useGetAziendeByCodeQuery,
    useGetComuniQuery, useGetLogsQuery,
    useGetPrestazioniQuery,
    useGetStruttureQuery
} from "../services/apiSlice";
import {Place} from "../assets/interface/interface";
import FormText from "./FormText";
import FormAutocomplete from "./FormAutocomplete";
import {infoTableMacellazioneOviCaprini, infoTableTariffeMacellazioneSuidi} from "../assets/utilities/variables";
import PriceInfoTable from "./PriceInfoTable";

interface Props {
    formik: any;
    tipologia_animali: string;
    goToBackTab(): void;
}

const BookingType: React.FC<Props> = (props: Props) => {

    const [isShowComune, setIsShowComune] = useState<boolean>(false);
    const [isShowSportello, setIsShowSportello] = useState<boolean>(false);
    const [isTracesSelected, setIsTracesSelected] = useState<boolean>(false);

    const mapRef = useRef<LeafletMap | null>(null);

    const initialPosition: any = [45.840748, 8.912932]; // Inserisci le coordinate iniziali qui
    const initialZoom = 9;

    const [struttura, setStruttura] = useState<Place>();

    const { data: comuniData, error: errorComuni } = useGetComuniQuery(null);
    const { data: struttureData, error: errorStrutture,  } = useGetStruttureQuery();
    const { data: prestazioniData, error: prestazioniError } = useGetPrestazioniQuery({
        reddito: props.tipologia_animali === "animali_da_reddito",
        tipo: "sportello"
    });
    
    const [codeSearch, setCodeSearch] = useState<string>("");

    const { data: aziendeByCodeData, error: errorAziendeByCode, refetch: refetchAziendeByCode} = useGetAziendeByCodeQuery({search: codeSearch},
        {
            skip: !codeSearch || codeSearch?.length < 2
        });

    const [coordinateAllevamento, setCoordinateAllevamento] = useState<string>();

    // Aggiungiamo un ref per tenere traccia del timeout
    const debounceTimeout = useRef<NodeJS.Timeout | null>(null);

    // Modifichiamo l'inizializzazione di displayIndirizzo e indirizzoAutocompletato
    const [indirizzoAutocompletato, setIndirizzoAutocompletato] = useState<boolean>(() => {
        // Se c'è già un'azienda e un sottocodice nel formik, assumiamo che l'indirizzo sia stato autocompletato
        return Boolean(props.formik.values.azienda && props.formik.values.sottocod_azienda);
    });

    const [displayIndirizzo, setDisplayIndirizzo] = useState<string>(() => {
        // Mostriamo l'indirizzo nel campo solo se:
        // 1. Esiste un indirizzo nel formik
        // 2. Non c'è un codice azienda (quindi è stato inserito manualmente)
        if (props.formik.values.indirizzo_azienda && !props.formik.values.azienda) {
            return props.formik.values.indirizzo_azienda;
        }
        return '';
    });

    // Aggiungiamo stati per i valori visualizzati nell'interfaccia
    const [displayComune, setDisplayComune] = useState<{value: string, label: string}>(() => {
        if (props.formik.values.comune_azienda) {
            // Se esiste già un comune nel formik, lo usiamo per inizializzare displayComune
            return {
                value: props.formik.values.comune_azienda,
                label: props.formik.values.comune_azienda
            };
        }
        return {value: '', label: ''};
    });

    // Modifichiamo l'inizializzazione di displayRagioneSociale
    const [displayRagioneSociale, setDisplayRagioneSociale] = useState<string>(() => {
        // Mostriamo la ragione sociale nel campo solo se:
        // 1. Esiste una ragione sociale nel formik
        // 2. Non c'è un codice azienda (quindi è stata inserita manualmente)
        if (props.formik.values.ragione_azienda && !props.formik.values.azienda) {
            return props.formik.values.ragione_azienda;
        }
        return '';
    });

    useEffect(() => {
        if (codeSearch?.length < 3) {
            return;
        }
        refetchAziendeByCode();
    }, [codeSearch]);

    useEffect(() => {
        setIsShowComune(Boolean(props.tipologia_animali === "animali_da_reddito"));
        setIsShowSportello(props.tipologia_animali === "animali_da_affezione");
    }, [props.tipologia_animali])

    useEffect(() => {
        if (props.formik.values?.tipo_prenotazione?.value) {
            // Controlla se la prestazione selezionata è TRACES
            const selectedPrestazione = prestazioniData?.find(
                prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value
            );
            
            const isTraces = selectedPrestazione?.descrizione.includes("TRACES") || false;
            setIsTracesSelected(isTraces);
            
            // Resetta i valori TRACES se la prestazione selezionata non è TRACES
            if (!isTraces && props.formik.values.traces_esecutore) {
                props.formik.setFieldValue("traces_esecutore", null);
                props.formik.setFieldValue("traces_note", "");
            }
        } else {
            setIsTracesSelected(false);
            // Resetta i valori TRACES se non è selezionata alcuna prestazione
            if (props.formik.values.traces_esecutore) {
                props.formik.setFieldValue("traces_esecutore", null);
                props.formik.setFieldValue("traces_note", "");
            }
        }
    }, [props.formik.values?.tipo_prenotazione, prestazioniData]);

    // Definisci l'icona personalizzata
    const customIcon = new L.Icon({
        iconUrl: "/images/maps-and-flags.png", // Percorso dell'immagine personalizzata
        iconSize: [32, 32], // Dimensioni dell'icona [larghezza, altezza]
        iconAnchor: [16, 32], // Punto in cui l'icona deve essere posizionata rispetto alla sua posizione
        popupAnchor: [0, -32], // Punto in cui deve essere posizionato il popup rispetto alla sua posizione
    });

    const containerActionsStyles = {
        marginTop: "40px"
    };

    const actionsStyles = {
        display: "flex",
        flexDirection: "row" as const,
        alignItems: "center",
        justifyContent: "end",
        gap: "15px",
    };

    const updateMapPositionWithTransition = (position: LatLngExpression, zoom: number) => {
        if (mapRef.current) {
            mapRef.current.flyTo(position, zoom, {
                duration: 1.5 // Durata della transizione in secondi
            });
        }
    };

    const handleMarkerSportelloClick = (pin: Place) => {
        if (!pin.id) {
            return;
        }
        updateMapPositionWithTransition(convertStringToNumberArray(pin.coordinate), 15);
        props.formik.setFieldValue("sportello", {value: pin.id.toString(), label: struttureData && struttureData.filter(str => str.id === pin.id)[0].descrizione});
    };

    const onChangeSportello = (change: any) => {
        if (!struttureData) {
            return;
        }
        const strutturaSelected = struttureData.filter(str => str.id === change.value)[0];
        setStruttura(strutturaSelected);
        updateMapPositionWithTransition(convertStringToNumberArray(strutturaSelected.coordinate), 15);
        props.formik.setFieldValue("sportello", change);
    }

    const returnCoordinateAllevamento = async (indirizzo: string) => {
        if(props.formik.values.azienda !== "") {
            return;
        }
        const indirizzoEncoded = indirizzo.replace(/ /g, '+');

        // Esegui la query per ottenere le coordinate
        fetch('https://nominatim.openstreetmap.org/search?q=' + indirizzoEncoded + '&format=json')
            .then(response => response.json())
            .then(data => {
                if (data && data[0] && data[0].lat && data[0].lon) {
                    const newCoordinate = data[0].lat + "," + data[0].lon;
                    if (coordinateAllevamento !== newCoordinate) {
                        setCoordinateAllevamento(newCoordinate);
                        updateMapPositionWithTransition(convertStringToNumberArray(newCoordinate), 15);
                    }
                }
            }, error => {
                console.error(error);
            });
    } 

    // Implementazione del debounce manuale
    const debouncedReturnCoordinateAllevamento = (indirizzo: string) => {
        // Cancelliamo il timeout precedente se esiste
        if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current);
        }
        
        // Impostiamo un nuovo timeout
        debounceTimeout.current = setTimeout(() => {
            if (indirizzo) {
                returnCoordinateAllevamento(indirizzo);
            }
        }, 800); // Aumentato da 500ms a 800ms
    };
    
    // Modifichiamo handleIndirizzoChange per gestire meglio l'input manuale
    const handleIndirizzoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const indirizzoValue = e.target.value;
        setDisplayIndirizzo(indirizzoValue);
        props.formik.setFieldValue("indirizzo_azienda", indirizzoValue);
        props.formik.setFieldValue("autocomplete", false); // Impediamo l'autocompletamento dopo la modifica manuale
        
        if (indirizzoValue && props.formik.values.comune_azienda) {
            const indirizzoCompleto = indirizzoValue + ", " + props.formik.values.comune_azienda;
            debouncedReturnCoordinateAllevamento(indirizzoCompleto);
        }
    };
    
    // Modifichiamo handleComuneChange per gestire sia il valore visualizzato che quello reale
    const handleComuneChange = (selectedOption: any) => {
        setDisplayComune(selectedOption);
        props.formik.setFieldValue("comune_azienda", selectedOption.label);
        
        // Aggiorniamo la mappa se abbiamo sia indirizzo che comune
        if (props.formik.values.indirizzo_azienda && selectedOption) {
            const indirizzoCompleto = props.formik.values.indirizzo_azienda + ", " + selectedOption.label;
            debouncedReturnCoordinateAllevamento(indirizzoCompleto);
        }
    };

    // Modifichiamo l'handler della ragione sociale per essere più semplice
    const handleRagioneAziendaChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setDisplayRagioneSociale(value);
        props.formik.setFieldValue("ragione_azienda", value);
        props.formik.setFieldValue("autocomplete", false); // Impediamo l'autocompletamento dopo la modifica manuale
    };
    
    // Modifichiamo l'useEffect che gestisce il caricamento iniziale
    useEffect(() => {
        // NON ripristiniamo più i valori dal formik quando c'è un codice azienda
        if (props.formik.values.autocomplete) {
            setDisplayRagioneSociale("");
            setDisplayIndirizzo("");
            
        } else {
            // Se c'è un codice azienda, manteniamo i display vuoti
            setDisplayRagioneSociale(props.formik.values.ragione_azienda);
            setDisplayIndirizzo(props.formik.values.indirizzo_azienda);
        }
        
        return () => {
            if (debounceTimeout.current) {
                clearTimeout(debounceTimeout.current);
            }
        };
    }, []);

    // Modifichiamo l'useEffect che gestisce l'autocompletamento
    useEffect(() => {
        if (aziendeByCodeData && aziendeByCodeData.length > 0) {
            const azienda = props.formik.values.sottocod_azienda 
                ? aziendeByCodeData.find(a => a.sottocod_azienda === props.formik.values.sottocod_azienda)
                : aziendeByCodeData[0];
            
            if (azienda) {
                // Aggiorniamo SOLO i valori nel formik se autocomplete è false
                if (!props.formik.values.autocomplete) {
                    props.formik.setFieldValue("indirizzo_azienda", azienda.indirizzo);
                    props.formik.setFieldValue("ragione_azienda", azienda.ragione);
                    // NON aggiorniamo i display
                    props.formik.setFieldValue("autocomplete", true);
                }
                
                // Aggiorniamo la mappa solo se abbiamo le coordinate
                if (azienda.coordinate) {
                    setCoordinateAllevamento(azienda.coordinate);
                    updateMapPositionWithTransition(convertStringToNumberArray(azienda.coordinate), 15);
                }
            }
        }
    }, [aziendeByCodeData, props.formik.values.sottocod_azienda]);

    const onChangeForm = (change: any) => {
        props.formik.handleChange(change);
        props.formik.validateForm();
    }

    const handleCodiceAllevamentoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const codiceValue = e.target.value;
        props.formik.setFieldValue("azienda", codiceValue);
        
        // Se il codice è vuoto o troppo corto
        if (!codiceValue || codiceValue.length < 3) {
            props.formik.setFieldValue("autocomplete", true);
            props.formik.setFieldValue("indirizzo_azienda", "");
            props.formik.setFieldValue("ragione_azienda", "");
            setDisplayIndirizzo("");
            setDisplayRagioneSociale("");
        } else if (codiceValue.length === 8) {
            // Se il codice è della lunghezza corretta
            setCodeSearch(codiceValue);
            props.formik.setFieldValue("autocomplete", false);
            // Resettiamo i display ma NON i valori del formik
            setDisplayIndirizzo("");
            setDisplayRagioneSociale("");
        }
        
        setTimeout(() => {
            props.formik.validateForm();
        }, 100);
    };

    // Modifichiamo anche handleSottocodiceAllevamentoChange per fare lo stesso
    const handleSottocodiceAllevamentoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const sottocodiceValue = e.target.value;
        props.formik.setFieldValue("sottocod_azienda", sottocodiceValue);
        
        // Forziamo la validazione immediata del form
        setTimeout(() => {
            props.formik.validateForm();
        }, 100);
        
        // Se il sottocodice viene cancellato, resettiamo lo stato e cancelliamo l'indirizzo
        if (!sottocodiceValue) {
            setIndirizzoAutocompletato(false);
            // Cancelliamo l'indirizzo se era stato autocompletato
            if (indirizzoAutocompletato) {
                props.formik.setFieldValue("indirizzo_azienda", "");
            }
        }
        
        // Se abbiamo sia il codice che il sottocodice, facciamo la ricerca
        if (props.formik.values.azienda && props.formik.values.azienda.length >= 3 && sottocodiceValue) {
            setCodeSearch(props.formik.values.azienda);
        }
    };

    const onKeyDown = (change: any) => {
        setCodeSearch(change.nativeEvent.srcElement.defaultValue);
    }

    const cancel = () => {
        props.goToBackTab();
    };

    // Aggiungiamo la validazione dell'indirizzo nel validateAzienda
    const validateAzienda = () => {
        if (props.formik.values.azienda && props.formik.values.sottocod_azienda) {
            // Se abbiamo i dati delle aziende e non troviamo corrispondenze
            if (aziendeByCodeData && aziendeByCodeData.length > 0) {
                const aziendaTrovata = aziendeByCodeData.find(a => 
                    a.sottocod_azienda === props.formik.values.sottocod_azienda && 
                    a.cod_azienda === props.formik.values.azienda
                );
                
                if (!aziendaTrovata) {
                    alert("Non esiste nessuna azienda con questo codice e sottocodice");
                    return false;
                }
            }
        }

        // Verifichiamo che l'indirizzo non sia vuoto
        if (!props.formik.values.indirizzo_azienda || props.formik.values.indirizzo_azienda.trim() === "") {
            alert("L'indirizzo non può essere vuoto");
            return false;
        }

        return true;
    };

    // Modifichiamo handleSubmit per usare i valori del display se presenti, altrimenti quelli del formik
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        
        // Se c'è un valore nel display, lo salviamo nel formik
        // altrimenti lasciamo il valore autocompletato
        if (displayRagioneSociale !== "") {
            props.formik.setFieldValue("ragione_azienda", displayRagioneSociale);
        }
        if (displayIndirizzo !== "") {
            props.formik.setFieldValue("indirizzo_azienda", displayIndirizzo);
        }
        
        // Se sono stati inseriti codice e sottocodice, verifichiamo che l'azienda esista
        if (props.formik.values.azienda && props.formik.values.sottocod_azienda) {
            if (!validateAzienda()) {
                return;
            }
        }
        
        props.formik.handleSubmit();
    };

    // Aggiungiamo un useEffect per sincronizzare displayComune quando il componente viene rimontato
    useEffect(() => {
        if (props.formik.values.comune_azienda && (!displayComune.label || displayComune.label !== props.formik.values.comune_azienda)) {
            setDisplayComune({
                value: props.formik.values.comune_azienda,
                label: props.formik.values.comune_azienda
            });
        }
    }, [props.formik.values.comune_azienda]);


    return (
        <Formik initialValues={props.formik.initialValues} onSubmit={props.formik.handleSubmit}>
            <Form onSubmit={handleSubmit}>
                <div className="p-4 rounded-lg mb-100">
                    <h2 className="my-3">
                        Tipologia
                    </h2>
                    <div className="pb-4">
                        <div className="my-3">
                            {prestazioniData && <FormSelect name="tipo_prenotazione" formik={props.formik} label="Tipologia Prestazione"
                                         value={props.formik.values.tipo_prenotazione}
                                         options={[...prestazioniData].sort((a, b) => a.descrizione.localeCompare(b.descrizione))}></FormSelect>}
                        </div>

                        { props.formik.values?.tipo_prenotazione?.value &&  <>

                            {isTracesSelected && (
                                <div className="my-4 p-3 border rounded">
                                    <h5>Opzioni TRACES</h5>
                                    <p>Si prega di specificare chi effettuerà la compilazione:</p>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Check
                                            type="radio"
                                            id="traces-allevatore"
                                            name="traces_esecutore"
                                            label="TRACES compilato dall'operatore"
                                            value="allevatore"
                                            checked={props.formik.values.traces_esecutore === "allevatore"}
                                            onChange={() => props.formik.setFieldValue("traces_esecutore", "allevatore")}
                                        />
                                        <Form.Check
                                            type="radio"
                                            id="traces-veterinario"
                                            name="traces_esecutore"
                                            label="TRACES compilato da ATS Insubria"
                                            value="veterinario"
                                            checked={props.formik.values.traces_esecutore === "veterinario"}
                                            onChange={() => props.formik.setFieldValue("traces_esecutore", "veterinario")}
                                        />
                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label>Note aggiuntive per TRACES (opzionale):</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="traces_note"
                                            value={props.formik.values.traces_note || ""}
                                            onChange={props.formik.handleChange}
                                            placeholder="Inserisci eventuali note..."
                                        />
                                    </Form.Group>
                                </div>
                            )}

                            {prestazioniData && prestazioniData.filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)[0]?.documenti.length > 0 && <div className="mt-1">
                                Prima di procedere ricordati di avere questi documenti:
                                <br/>
                                <br/>
                                <ul>
                                    {prestazioniData && prestazioniData.filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)[0]?.documenti.map((doc, index) => (
                                        <li key={index}>{doc}</li>))}
                                </ul>
                            </div>}

                            {props.tipologia_animali === "animali_da_affezione" && <div>
                                Il costo unitario della prestazione è di:

                                {
                                    prestazioniData && prestazioniData
                                        .filter(prestazione => prestazione.id === props.formik.values?.tipo_prenotazione?.value)
                                        .map(prestazione => (
                                            prestazione.tariffe && (
                                                <React.Fragment key={prestazione.id}>
                                                    <ul className="mb-0">
                                                        {prestazione.tariffe.map((tariffa, index) => (
                                                            <li key={"tariffa" + index}>€ {tariffa.prezzo}: {tariffa.descrizione_tariffa}</li>
                                                        ))}
                                                    </ul>
                                                </React.Fragment>
                                            )
                                        ))
                                }

                                <br/>
                                <br/>

                                In caso di prenotazione per più animali il costo della prestazione va moltiplicato per
                                il numero di animali. Se il totale è uguale o superiore a 77,47€ vanno aggiunti 2 € del
                                bollo.



                            </div>}


                            {(props.tipologia_animali === "animali_da_affezione" || (props.tipologia_animali === "animali_da_reddito" && props.formik.values?.tipo_prenotazione?.value === "25")) && <>
                                <br/>
                                <br/>

                                * Per effettuare il pagamento accedi al sito web di <a
                                href="https://pagamentinlombardia.servizirl.it/pa/home.html" target="_blank"> Regione
                                Lombardia</a> nella sezione <b>Cerca Ente</b> seleziona <a>ATS dell'INSUBRIA</a>, scegli
                                tra le tipologie di servizio la voce <b>Servizio Veterinario</b> e procedi con il
                                pagamento della specifica tariffa.
                            </>}


                            {(props.formik.values?.tipo_prenotazione?.value === "1" || props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                ** Informarsi presso Ambasciata/Consolato/sito ufficiale.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "1") && <>
                                <br/>
                                <br/>
                                *** Scarica qui il documento per la <a target="_blank" href="/allegati/documenti_a60/Rich_Passaporto_2022.pdf">Richiesta di Rilascio Passaporto</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "2") && <>
                                <br/>
                                <br/>

                                ** Scarica qui il documento per la <a target="_blank" href="/allegati/documenti_a60/Modulo_iscrizione_anagrafe_canina_22.pdf">Dichiarazione cessione animale</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                *** Scarica qui il documento per il <a target="_blank" href="/allegati/documenti_a13_05/Rich_CertificatoEspatrio_2022.pdf">Certificato di Espatrio</a>.
                            </>}

                            {(props.formik.values?.tipo_prenotazione?.value === "3") && <>
                                <br/>
                                <br/>

                                **** Da presentare al momento del ritiro del certificato, rispettando il termine delle 48 ore.
                            </>}

                            <br/>
                            <br/>

                            {props.tipologia_animali === "animali_da_affezione" && <u><i>Presentarsi per il ritiro di persona senza animale o con delega del proprietario allegando fotocopia di un documento d'Identità valido.</i></u>}

                            {props.tipologia_animali === "animali_da_reddito" 
                                && props.formik.values?.tipo_prenotazione?.value === "25" 
                                && <PriceInfoTable tariffeMacellazione={infoTableTariffeMacellazioneSuidi}/>
                            }

                            {props.tipologia_animali === "animali_da_reddito" 
                                && props.formik.values?.tipo_prenotazione?.value === "31" 
                                && <PriceInfoTable tariffeMacellazione={infoTableMacellazioneOviCaprini}/>
                            }

                        </>}

                    </div>

                    <hr />


                    <h2 className="my-3 pt-3">
                        Posizione
                    </h2>
                    <div className="row">
                        <div className="col-md-6 my-3">
                            <div style={{ width: '100%', height: '400px' }}>
                                <MapContainer ref={mapRef} center={initialPosition} zoom={initialZoom}
                                               style={{width: "100%", height: '100%', zIndex: 0}}>
                                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>

                                    {isShowSportello && struttureData && struttureData.map((pin) => (
                                        <Marker
                                            key={pin.id}
                                            position={convertStringToNumberArray(pin.coordinate)}
                                            icon={customIcon}
                                            eventHandlers={{
                                                click: () => handleMarkerSportelloClick(pin)
                                            }}
                                        >
                                        </Marker>
                                    ))}

                                    {coordinateAllevamento && <Marker
                                        position={convertStringToNumberArray(coordinateAllevamento)}
                                        icon={customIcon}></Marker>}

                                </MapContainer>
                            </div>
                        </div>


                        <div className="col-md-6 my-3">

                            {isShowComune ? (
                                <div className="d-flex flex-column gap-3">
                                    <div className="row">
                                        <div className="col-md-8">
                                            <FormText 
                                                name="azienda" 
                                                value={props.formik.values.azienda || ''} 
                                                onChange={handleCodiceAllevamentoChange}
                                                error={props.formik.errors["azienda"]}
                                                type="text" 
                                                label="Codice Allevamento" 
                                                placeholder="Inserisci il codice allevamento" 
                                            />
                                        </div>
                                        <div className="col-md-4">
                                            <FormText 
                                                name="sottocod_azienda" 
                                                value={props.formik.values.sottocod_azienda || ''} 
                                                onChange={handleSottocodiceAllevamentoChange}
                                                error={props.formik.errors["sottocod_azienda"]}
                                                type="text" 
                                                label="Sottocodice" 
                                                placeholder="Sottocodice" 
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* Mostriamo sempre i campi indirizzo e comune */}
                                    <div className="col-md-24">
                                    <div className="col-12">
                                            <FormText 
                                                name="ragione_azienda" 
                                                value={displayRagioneSociale} 
                                                onChange={handleRagioneAziendaChange}
                                                error={props.formik.errors["ragione_azienda"]}
                                                type="text" 
                                                label="Nome operatore/allevatore" 
                                                placeholder="Inserisci il nome operatore/allevatore" 
                                            />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <FormSelect 
                                                name="comune_azienda" 
                                                value={displayComune} 
                                                onChange={handleComuneChange}
                                                formik={props.formik}
                                                label="Comune" 
                                                error={props.formik.errors["comune_azienda"]}
                                                options={comuniData || []}
                                                placeholder="Seleziona il comune" 
                                            />
                                        </div>
                                        <div className="col-12">
                                            <FormText 
                                                name="indirizzo_azienda" 
                                                value={displayIndirizzo || ''} 
                                                onChange={handleIndirizzoChange}
                                                error={props.formik.errors["indirizzo_azienda"]}
                                                type="text" 
                                                label="Indirizzo" 
                                                placeholder="Inserisci l'indirizzo" 
                                            />
                                        </div>
                                        
                                    </div>

                                </div>
                            ) : (
                                struttureData ? (
                                    <FormSelect
                                        name="sportello"
                                        formik={props.formik}
                                        label="Sportello"
                                        value={props.formik.values.sportello}
                                        options={struttureData}
                                        onChange={onChangeSportello}
                                    />
                                ) : null
                            )}


                            {isShowSportello && struttura && <div>
                                <br/><br/>
                                <a href="mailto:">{ struttura.email}</a>
                                <p>
                                    { struttura.indirizzo}<br/>
                                    Tel. <a href={ "tel:+39" + struttura.telefono_1}>{ struttura.telefono_1}</a> {struttura.telefono_2 && <span>- <a href={ "tel:+39" + struttura.telefono_2}>{ struttura.telefono_2}</a></span>}
                                </p>
                            </div>}

                        </div>

                    </div>

                    <div style={containerActionsStyles}>
                        <hr/>
                        <div style={actionsStyles}>
                            <div>
                                <Button className="bg-white" variant="outline-primary"
                                        onClick={cancel}>Indietro</Button>
                            </div>
                            <div>
                                <Button variant="primary" type="submit" disabled={!props.formik.isValid || !props.formik.dirty}>Prossimo</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default BookingType;

