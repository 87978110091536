import Form from 'react-bootstrap/Form';
import {FormGroup} from "react-bootstrap";
import React, {useEffect} from "react";
import {ErrorMessage, useFormik} from "formik";

interface Props {
    name?: string;
    type: string;
    label: string;
    value: string;
    placeholder?: string;
    disabled?: boolean;
    note?: string;
    error?: string;
    max?: any;
    min?: any;
    onChange?(event?: any): void;
}
export default function FormText(props: Props ) {

    const isDirty = props.value !== "";

    return (
        <FormGroup>
            <Form.Label className="label">
                {props.label}
            </Form.Label>
            <Form.Control type={props.type} disabled={props.disabled} placeholder={props.placeholder} name={props.name} value={props.value} onChange={props.onChange} max={props.max} min={props.min}/>
            { props.note && <Form.Text muted>{props.note}</Form.Text>}<br/>
            {props.error && <Form.Text className="mt-2" style={{color: "#FF0000"}}>
                {props.error}
            </Form.Text>}
        </FormGroup>
    );
}
