// Prezzi tariffa Macellazione
import FormText from "../../components/FormText";
import FormTextArea from "../../components/FormTextArea";

export const infoTableTariffeMacellazioneSuidi = {
    nome: "SUINO",
    quantita: {
        1: {
            codice: "A.21.01 (1) + A.21.01T (1)",
            tariffa: 16.67
        },
        2: {
            codice: "A.21.01 (1) + A.21.02 (1) + A.21.01T (2)", 
            tariffa: 23.34
        },
        3: {
            codice: "A.21.01 (1) + A.21.02 (2) + A.21.01T (3)",
            tariffa: 30.01
        },
        4: {
            codice: "A.21.01 (1) + A.21.02 (3) + A.21.01T (4)",
            tariffa: 36.68
        }
    }
} as const;

export const infoTableMacellazioneOviCaprini = {
    nome: "OVI-CAPRINO",
    quantita: {
        1: {
            codice: "A.21.01 (1)",
            tariffa: 15.00  
        },
        2: {
            codice: "A.21.01 (1) + A.21.02 (1)",
            tariffa: 20.00
        },
        3: {
            codice: "A.21.01 (1) + A.21.02 (2)",
            tariffa: 25.00
        },
        4: {
            codice: "A.21.01 (1) + A.21.02 (3)",
            tariffa: 30.00
        },
        5: {
            codice: "A.21.01 (1) + A.21.02 (4)",
            tariffa: 35.00
        },
        6: {
            codice: "A.21.01 (1) + A.21.02 (5)",
            tariffa: 40.00
        }
    }
}

export const formMail = (formikProps: any) => (
    <FormText
        name="email"
        value={formikProps.email}
        onChange={formikProps.handleChange}
        error={formikProps.errors["email"]}
        type="text"
        label="Email"
        placeholder="Inserisci qui"
    />
);

export const formTelefono = (formikProps: any) => (
    <FormText
        name="telefono"
        value={formikProps.telefono}
        onChange={formikProps.handleChange}
        error={formikProps.errors["telefono"]}
        type="number"
        label="Telefono"
        placeholder="Inserisci qui"
    />
);

export const formAnimaliPerPrenotazione = (formikProps: any, tipologia: string) => (
    <FormText
        name="animali_per_prenotazione"
        value={formikProps.values.animali_per_prenotazione}
        onChange={formikProps.handleChange}
        error={formikProps.errors["animali_per_prenotazione"]}
        type="number"
        label={"Numero animali per prenotazione"}
        note={tipologia == "25" ? "Il numero massimo di animali consentiti è di 4 maiali all’anno" : tipologia == "31" ? "Il numero massimo di animali consentiti è di 6 ovi-caprini di età inferiore ai 6 mesi per nucleo familiare e la macellazione a domicilio è riservata agli allevatori ovi-caprini registrati nell’anagrafe zootecnica." : ""}
        max={tipologia == "25" ? 4 : tipologia == "31" ? 6 : ""}
        min={1}
        placeholder="Inserisci qui"
    />
);

export const formNotes = (formikProps: any) => (
    <FormTextArea
        name="notes"
        value={formikProps.notes}
        onChange={formikProps.handleChange}
        label="Note"
        placeholder="Inserisci qui"
    />
);
