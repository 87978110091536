import React, {useContext, useEffect, useState} from 'react';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import BookingType from "../components/BookingType";
import OwnershipData from "../components/OwnershipData";
import Documents from "../components/Documents";
import Calendar from "../components/Calendar";
import Summary from "../components/Summary";
import {useFormik} from "formik";
import * as Yup from 'yup';
import ModalConfirm from "../components/ModalConfirm";
import {Book} from "../assets/interface/interface";
import {useNavigate} from "react-router-dom";
import {cleanJSON, isValidCodiceFiscale} from "../assets/utilities/utilities";
import {faCircleCheck} from "@fortawesome/free-regular-svg-icons";
import Loading from "../components/Loading";
import {config} from "../config";
import {ToastContext} from "../services/ToastService";
import AnimalsType from "../components/AnimalsType";
import {addHours, format, formatISO} from "date-fns";

const CreateBooking = () => {

    const [key, setKey] = useState<string>('animali');
    const [tabs, setTabs] = useState(['animali', 'prenotazione', 'informazioni-personali', 'documenti', 'calendario', 'riepilogo']);

    const [modalShow, setModalShow] = useState<boolean>(false);

    const [book, setBook] = useState<Book | any>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const fileTypes = ['image/jpeg', 'image/png', 'application/pdf', 'application/x-zip-compressed'];

    // Definisci il limite di dimensione del file (10 MB in byte)
    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10 M
    const {showToast} = useContext(ToastContext)!;

    const goToNextTab = () => {
        const currentIndex = tabs.indexOf(key);
        const nextIndex = currentIndex + 1;
        if (nextIndex < tabs.length) {
            setKey(tabs[nextIndex]);
        }
    };

    const goToBackTab = () => {
        const currentIndex = tabs.indexOf(key);
        const nextIndex = currentIndex - 1;
        if (nextIndex < tabs.length) {
            setKey(tabs[nextIndex]);
        }
    };

    const formikAnimals = useFormik({
        initialValues: {
            tipologia_animali: null,
            reddito: false,
        },
        validationSchema: Yup.object({
            tipologia_animali: Yup.mixed().required(""),
        }),
        onSubmit: (values) => {

            if (values.tipologia_animali != book.tipologia_animali) {
                setBook({});
            }

            formikBookingType.setFieldValue('tipo_prenotazione', null);

            values.reddito = values.tipologia_animali === "animali_da_reddito";
            formikBookingType.setFieldValue('tipo_prenotazione', null);

            if (book.tipo_prenotazione) {
                book["tipo_prenotazione"] = null;
            }
            setBook({...book, ...values});

            goToNextTab();
            scrollToTop();
        }
    });

    const formikBookingType = useFormik({
        initialValues: {
            tipo_prenotazione: null,
            sportello: null,
            indirizzo_azienda: null,
            comune_azienda: null,
            sottocod_azienda: null,
            ragione_azienda: "",
            azienda: "",
            traces_esecutore: null,
            traces_note: "",
            autocomplete: false
        },
        validationSchema: Yup.object().shape({
            tipo_prenotazione: Yup.mixed().required(""),
            sportello: book.tipologia_animali === "animali_da_affezione" ? Yup.mixed().required("") : Yup.mixed().nullable(),
            azienda: book.tipologia_animali === "animali_da_reddito" ? Yup.mixed().required().when('indirizzo_azienda', (indirizzo_azienda, schema) => {
                return !indirizzo_azienda || indirizzo_azienda.length === 0 || indirizzo_azienda[0] === undefined || indirizzo_azienda[0] === null || indirizzo_azienda[0] === ""
                    ? schema.required("Devi inserire un codice azienda se non hai un indirizzo").nonNullable()
                    : schema.notRequired();
            }) : Yup.mixed().nullable(),
            indirizzo_azienda: book.tipologia_animali === "animali_da_reddito" ? 
                Yup.mixed().required().when('azienda', (azienda, schema) => {
                    return !azienda || azienda.length === 0 || azienda[0] === undefined || azienda[0] === null || azienda[0] === ""
                        ? schema.required("Devi inserire l'indirizzo se non hai un codice azienda")
                        : schema.notRequired();
                }) : Yup.mixed().nullable(),
            comune_azienda: book.tipologia_animali === "animali_da_reddito" ? 
                Yup.mixed().required("Il comune è obbligatorio") : Yup.mixed().nullable(),
            traces_esecutore: Yup.mixed().when('tipo_prenotazione', (tipo_prenotazione, schema) => {
                // Utilizziamo una funzione ausiliaria per controllare in modo sicuro
                const checkTracesInLabel = (obj: any): boolean => {
                    return obj && 
                           typeof obj === 'object' && 
                           'label' in obj && 
                           typeof obj.label === 'string' && 
                           obj.label.toLowerCase().includes('traces');
                };
                
                // Caso array
                if (Array.isArray(tipo_prenotazione) && 
                    tipo_prenotazione.length > 0 && 
                    checkTracesInLabel(tipo_prenotazione[0])) {
                    return schema.required("");
                }
                
                return schema.notRequired();
            }),
        }, [['azienda', 'indirizzo_azienda']]),
        onSubmit: (values) => {
            setBook({...book, ...values});
            goToNextTab();
            scrollToTop();
        }
    });

    const formikOwnershipData = useFormik({
        initialValues: {
            nome: "",
            cognome: "",
            codicefiscale: "",
            indirizzo:  "",
            comune: "",
            ragione: "",
            cap: null,
            provincia: "",
            telefono: "",
            email: "",
            documento_carta_identita: "",
            documento_codicefiscale: "",
            animali_per_prenotazione: 1,
            data_di_acquisto: "",
        },
        validationSchema: Yup.object({
            nome: book.tipologia_animali === "animali_da_affezione" ? Yup.string().required("") : Yup.string().nullable(),
            cognome: book.tipologia_animali === "animali_da_affezione" ? Yup.string().required("") : Yup.string().nullable(),
            codicefiscale: book.tipologia_animali === "animali_da_affezione" ? Yup.string().required("").test(
                'isValidCodiceFiscale',
                'Il codice fiscale non è valido',
                value => isValidCodiceFiscale(value || ''),
            ) : Yup.string().nullable(),
            indirizzo: book.tipologia_animali === "animali_da_affezione" ? Yup.string().required("") : Yup.string().nullable(),
            citta: book.tipologia_animali === "animali_da_affezione" ? Yup.string().required("") : Yup.string().nullable(),
            cap: book.tipologia_animali === "animali_da_affezione" ? Yup.number().required("") : Yup.number().nullable(),
            provincia: book.tipologia_animali === "animali_da_affezione" ? Yup.string().required("") : Yup.string().nullable(),
            telefono: Yup.number().required(""),
            email: Yup.string().email("Il campo deve essere composto da una mail").required(""),

            documento_carta_identita: book.tipologia_animali === "animali_da_affezione" ? Yup.mixed().nullable()
                .test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                    if (!value) return false;
                    return fileTypes.includes(value.type);
                })
                .test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                    if (!value) return false;
                    return value.size <= MAX_FILE_SIZE;
                }) : Yup.mixed().nullable(),

            documento_codicefiscale: book.tipologia_animali === "animali_da_affezione" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            animali_per_prenotazione: Yup.number()
                .required("")
                .positive("Il numero di animali deve essere maggiore di zero")
                .test(
                    "max-animali-tipologia",
                    "Il numero di animali deve essere minore o uguale a 4 per questa tipologia.",
                    function (value) {
                        if (book.tipo_prenotazione.value == "25") {
                            return value < 5;
                        }
                        return true; // Se la tipologia non è '25', non applicare la restrizione.
                    }
                ),
        }),
        onSubmit: (values) => {
            setBook({...book, ...values});

            goToNextTab();
            scrollToTop();
        }
    });

    const formikDocuments = useFormik({
        initialValues: {
            certificato_vaccinazione_antirabbica: "",
            certificato_iscrizione_anagrafe: "",
            certificazione_microchip: "",
            certificato_espatrio: "",
            documento_richiesta_passaporto: "",
            eventuali_documenti: "",
            delega: false,
            documento_delega: "",
            documento_di_pagamento: "",
            documento_cedente_acquirente: "",
            privacy: false,
            notes: "",
        },
        validationSchema: Yup.object({
            certificato_vaccinazione_antirabbica: (book.tipo_prenotazione?.value == "3" || book.tipo_prenotazione?.value == "1")  ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (book.tipo_prenotazione?.value == "1" && !value) {
                    return true
                } else if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (book.tipo_prenotazione?.value == "1" && !value) {
                    return true
                } else if (!value) {
                    return false;
                }
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),


            certificato_iscrizione_anagrafe: (book?.tipo_prenotazione?.value == "1" || book?.tipo_prenotazione?.value == "3")  ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            certificazione_microchip:( book?.tipo_prenotazione?.value === "3" || book?.tipo_prenotazione?.value === "1") ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            certificato_espatrio: book?.tipo_prenotazione?.value === "3" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            documento_richiesta_passaporto: book?.tipo_prenotazione?.value === "1" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            documento_cessione_cane: book?.tipo_prenotazione?.value === "2" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            documento_cedente_acquirente: book?.tipo_prenotazione?.value === "2" ? Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),
            eventuali_documenti: (book?.tipo_prenotazione?.value == "1" || book?.tipo_prenotazione?.value == "3") ? Yup.mixed()
                .test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP ciao', (value: any) => {
                    if (!value) {
                        return true;
                    }
                    return fileTypes.includes(value.type);
                })
                .test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                    if (!value) return true;
                    return value.size <= MAX_FILE_SIZE;
                })
                    : Yup.mixed().nullable(),
            delega: Yup.boolean(),
            documento_delega: Yup.mixed().when('delega', ([field]) => {
                if (field) {
                    return Yup.mixed().test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                        if (!value) {
                            return false;
                        }
                        return fileTypes.includes(value.type);
                    }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                        if (!value) return false;
                        return value.size <= MAX_FILE_SIZE;
                    })
                } else {
                    return Yup.mixed().nullable();
                }
            }),
            documento_di_pagamento: (book?.tipologia_animali == "animali_da_affezione" || book?.tipo_prenotazione?.value == "25") ? Yup.mixed().required("").test('fileType', 'Il file deve essere di tipo PDF, PNG, JPEG o una Cartella ZIP', (value: any) => {
                if (!value) {
                    return false;
                }
                return fileTypes.includes(value.type);
            }).test('fileSize', 'Il file deve essere inferiore a 10 MB', (value: any) => {
                if (!value) return false;
                return value.size <= MAX_FILE_SIZE;
            }) : Yup.mixed().nullable(),

            privacy: Yup.boolean().required("").oneOf([true]),
            notes: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            setBook({...book, ...values});
            goToNextTab();
            scrollToTop();
        }
    });

    const formikCalendar = useFormik({
        initialValues: {
            date_prenotazione: null,
        },
        validationSchema: Yup.object({
            date_prenotazione: Yup.date().required(""),
        }),
        onSubmit: (values) => {
            setBook({...book, ...values});
            goToNextTab();
            scrollToTop();
        }
    });

    const handleCreateBook = () => {
        setModalShow(false);
        createBook();
    }

    const createBook = () => {
        setIsLoading(true);

        if (book?.tipologia_animali === "animali_da_reddito") {
            book["cod_azienda"] = book.azienda;
            book["citta"] = book.comune_azienda;
            book["sottocod_azienda"] = book.sottocod_azienda ?? "1";
            book["ragione"] = book.ragione_azienda;
            book["indirizzo"] = book.indirizzo_azienda;
            book["booking_date_time_start"] = formatISO(book.date_prenotazione);
            delete book.azienda;
        }
        

        fetch(`${config.apiUrl}/front/insert_prenotazione2.php`, {
            method: 'POST',
            body: JSON.stringify(cleanJSON({...book})),
        })
            .then(response => response.json())
            .then(data => {
                const id = data.order;
                if (book.tipologia_animali === "animali_da_reddito") {
                    navigate('booking/' + id + '/' + book.tipologia_animali);
                } else {
                    navigate('booking-request/' + id + '/' + book.tipologia_animali);
                }
            })
            .catch(error => {
                setIsLoading(false);
                showToast("danger", "Errore", "Errore durante la creazione della prenotazione");
                console.error('Errore durante l\'invio della post:', error);
            });
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (true) {
        return (
            <div className="container">
                <h1>Richiesta di prenotazione prestazione</h1>
                <p>Scegli la tua prestazione e compila tutti i campi per la creazione della prenotazione del tuo appuntamento. Se hai bisogno di assistenza, contatta lo sportello selezionato. Forniamo un servizio clienti dedicato per rendere il processo di prenotazione semplice ed efficiente.</p>

                <div className="tab-container">
                    <h2 className="fw-bold fs-4 mb-4">{ book && book?.tipo_prenotazione?.label }</h2>

                    <Tab.Container defaultActiveKey="prenotazione" activeKey={key}>
                        <Row>
                            <Col sm={9} className="mb-5">
                                <Tab.Content className="tab-container-detail">

                                    <Tab.Pane eventKey="animali">
                                        <AnimalsType formik={formikAnimals} />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="prenotazione">
                                        {book && key === "prenotazione" && (
                                            <BookingType formik={formikBookingType} goToBackTab={goToBackTab} tipologia_animali={book.tipologia_animali}/>
                                        )}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="informazioni-personali">
                                        <OwnershipData
                                            book={book}
                                            tipologia_animali={book.tipologia_animali}
                                            formik={formikOwnershipData}
                                            goToBackTab={goToBackTab}
                                            tipologia={book?.tipo_prenotazione?.value}
                                        />
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="documenti">
                                        {book && book.tipo_prenotazione && (
                                            <Documents
                                                tipologia_animali={book.tipologia_animali}
                                                typeBooking={book.tipo_prenotazione}
                                                formik={formikDocuments}
                                                animali_per_prenotazione={book.animali_per_prenotazione}
                                                goToBackTab={goToBackTab}
                                            />
                                        )}
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="calendario">
                                        <Calendar book={book}
                                                   goToBackTab={goToBackTab}
                                                   formik={formikCalendar}
                                                   tipologia_animali={book.tipologia_animali}/>
                                    </Tab.Pane>

                                    <Tab.Pane eventKey="riepilogo">
                                        {book && book.tipo_prenotazione && key === "riepilogo" && (
                                            <Summary
                                                isSummary={key === "riepilogo"}
                                                book={book}
                                                goToBackTab={goToBackTab}
                                                modalShow={() => setModalShow(true)}
                                            />
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>

                            <Col sm={3}>
                                <Nav variant="pills" className="flex-column">

                                    {tabs.includes("animali") && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="animali" className={(formikAnimals.isValid && formikAnimals.dirty) ? "isValid" : ""}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>Animali</div>
                                                    <div className="text-primary icon-check">
                                                        <FontAwesomeIcon icon={faCircleCheck}/>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}

                                    {tabs.includes("prenotazione") && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="prenotazione" className={(formikBookingType.isValid && formikBookingType.dirty) ? "isValid" : ""}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>Prenotazione</div>
                                                    <div className="text-primary icon-check">
                                                        <FontAwesomeIcon icon={faCircleCheck}/>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}

                                    {tabs.includes("informazioni-personali") && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="informazioni-personali" className={(formikOwnershipData.isValid && formikOwnershipData.dirty) ? "isValid" : ""}
                                                      disabled={!formikBookingType.isValid || !formikBookingType.dirty}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>Informazioni Personali</div>
                                                    <div className="text-primary icon-check">
                                                        <FontAwesomeIcon icon={faCircleCheck}/>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}

                                    {tabs.includes("documenti") && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="documenti" className={(formikDocuments.isValid && formikDocuments.dirty) ? "isValid" : ""}
                                                      disabled={!formikOwnershipData.isValid || !formikOwnershipData.dirty}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>{formikAnimals.values.tipologia_animali == "animali_da_affezione" ? 'Doocumenti' : 'Informazioni Aggiuntive'}</div>
                                                    <div className="text-primary icon-check">
                                                        <FontAwesomeIcon icon={faCircleCheck}/>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}

                                    {tabs.includes("calendario") && (
                                        <Nav.Item>
                                            <Nav.Link eventKey="calendario" className={(book?.date_prenotazione) ? "isValid" : ""}
                                                      disabled={!formikDocuments.isValid || !formikDocuments.dirty}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div>Calendario</div>
                                                    <div className="text-primary icon-check">
                                                        <FontAwesomeIcon icon={faCircleCheck}/>
                                                    </div>
                                                </div>
                                            </Nav.Link>
                                        </Nav.Item>
                                    )}

                                    {tabs.includes("riepilogo") && (
                                        <Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="riepilogo"
                                                          disabled={!book.date_prenotazione}>Riepilogo</Nav.Link>
                                            </Nav.Item>
                                        </Nav.Item>
                                    )}

                                </Nav>

                                { (key === "informazioni-personali" || key === "documenti") &&
                                    <div className="mt-4">
                                        <div className="bg-secondary p-4">
                                            <div>
                                                <div className="d-flex flex-row gap-3 align-items-center">
                                                    <div className="fs-3 text-primary">
                                                        <FontAwesomeIcon icon={faTriangleExclamation}/>
                                                    </div>
                                                    <div className="fw-bold text-uppercase text-primary">
                                                        ATTENZIONE
                                                    </div>
                                                </div>

                                                <ol className="mt-3">
                                                    <li>Per il caricamento dei file è necessario utilizzare un file di tipo PDF, PNG, JPEG o una Cartella ZIP.</li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                }

                            </Col>
                        </Row>
                    </Tab.Container>
                </div>

                {book && <ModalConfirm book={book} modalShow={modalShow} setModalShow={setModalShow} createBook={handleCreateBook}></ModalConfirm>}

                { isLoading && <Loading />}
            </div>
        );
    } else {
        return <Loading />
    }
};

export default CreateBooking;
