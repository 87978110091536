import React, {useContext, useEffect, useRef, useState} from 'react';
import {Button, Spinner, Table, Tabs, Modal} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import SliderComponent from "../components/SliderComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faClock} from "@fortawesome/free-regular-svg-icons";
import {faLocationDot} from "@fortawesome/free-solid-svg-icons";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
    cleanJSON,
    formatDate, formatDateTime,
    formatMinutesToTime,
    getFollowingDays
} from "../assets/utilities/utilities";
import {format} from "date-fns";
import {ToastContext} from "../services/ToastService";
import UserTimeline from "../components/UserTimeline";
import ModalSummary from "../components/ModalSummary";
import {Logs, Service, User} from "../assets/interface/interface";
import ModalNewService from "../components/ModalNewService";
import {useFormik} from "formik";
import * as Yup from "yup";
import AvatarUser from "../components/AvatarUser";
import {Status} from "../assets/utilities/enum";
import {config} from "../config";
import {
    useCreateAssignBookingMutation,
    useCreateDistrictServiceMutation, useGetEventByVetIdQuery,
    useGetEventsToAssignQuery, useGetLogsQuery, useGetVeterinaryDayBookingsQuery,
    useGetPrestazioniQuery,
    useGetSessionQuery, useGetVeterinariQuery, useGetDistrictsByUserQuery, useSetDistrictMutation
} from "../services/apiSlice";
import {useNavigate} from "react-router-dom";
import FormSelect from "../components/FormSelect";
import EventDetailsModal from "../components/EventDetailsModal";

const FarmAnimalActivities = () => {

    /* ###########   Generic  ########### */

    const [districtId, setDistrictId] = useState<number>();
    const [eventKey, setEventKey] = useState("service_to_assign");
    const navigate = useNavigate();
    const [setDistrict, result] = useSetDistrictMutation();

    function selectTab(k: any) {
        if (k === eventKey) {
            return;
        }
        setEventKey(k);
        setDateStartSelected("");
        setDateEndSelected("");
    }

    function goToCalendarPets() {
        window.location.href = `${config.apiUrl}/admin/calendar.php`;
    }


    /* ###########   ⚠️ Gestione Errori  ########### */

    const {showToast} = useContext(ToastContext)!;


    /* ###########   📱 Mobile  ########### */

    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleWindowResize = () => setIsMobile(window.innerWidth < 768);
        window.addEventListener('resize', handleWindowResize);
        handleWindowResize();
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    /* Verificare se la sessione è di Profilo Admin */
    const { data: sessionData, error: errorSessionData, isLoading } = useGetSessionQuery();

    useEffect(() => {
        if (errorSessionData) {
            showToast("danger", "Errore", "Errore nel caricamento dei dati di sessione.");
        }
    }, [errorSessionData]);

    // Verifica profilo utente
    useEffect(() => {
        if (!isLoading && sessionData) {
            setDistrictId(sessionData.district_id);
            // Se l'utente non è admin, reindirizza
            if (!sessionData.logged || (sessionData.role !== 'adminreddito' && sessionData.role !== 'adminall')) {
                window.location.href = `${config.apiUrl}/admin/`;
            }
        }
    }, [sessionData, isLoading, navigate]);


    /* ###########   🚀 Sezione Prestazioni da assegnare  ########### */

    const [activeCardIndex, setActiveCardIndex] = useState<number | null>();

    const { data: prestazioniData,
        isLoading: isLoadingPrestazioni,
        error: prestazioniError } = useGetPrestazioniQuery({
        reddito: true,
        tipo: ""
    });

    useEffect(() => {
        if (prestazioniError) {
            showToast("danger", "Errore", "Errore nel caricamento delle prestazioni.");
        }
    }, [prestazioniError]);

    const {
        data: servicesToAssignData,
        error: errorServicesToAssign,
        isLoading: isLoadingServicesToAssign,
        refetch: refetchServicesToAssignData } = useGetEventsToAssignQuery(districtId, {
            skip: !districtId
        });

    useEffect(() => {
        if (errorServicesToAssign) {
            showToast("danger", "Errore", "Errore nel caricamento delle prestazioni da assegnare.");
        }
    }, [errorServicesToAssign]);

    useEffect(() => {
        if (!servicesToAssignData || servicesToAssignData.length === 0){
            return;
        }

        if (newServiceId) {
            const index = servicesToAssignData.findIndex((service) => service.id == newServiceId);
            if (index > -1) {
                handlePrestazioneSelect(index);
                setSlickGoToIndex(index - 1);
            }
            setNewServiceId(null);
        } else {
            handlePrestazioneSelect(0);
            setSlickGoToIndex(0);
        }

    }, [servicesToAssignData]);

    const handlePrestazioneSelect = (index: number) => {
        if (activeCardIndex == index) {
            return;
        }
        setActiveCardIndex(index);
        if (!servicesToAssignData || servicesToAssignData.length === 0){
            return;
        }
        if (servicesToAssignData && servicesToAssignData[index]){
            setServiceSelected(servicesToAssignData[index]);
        }
    };


    /* ###########   📅 Calendario  ########### */

    const refCalendar = useRef<any>(null);
    const [slot, setSlot] = useState<number>(30);
    const [vetId, setVetId] = useState<number | null>(null);
    const [businessHours, setBusinessHours] = useState<any[]>();
    const [events, setEvents] = useState<any[]>([]);
    const [smallestStartTime, setSmallestStartTime] = useState<string>("07:00:00");
    const [largestEndTime, setLargestEndTime] = useState<string>("20:00:00");
    const [startDate, setStartDate] = useState<any>(getFollowingDays([0, 6], 0));
    const validRange = {start: startDate};

    // Prendi tutti i veterinari
    const {
        data: veterinariData,
        error: errorVeterinari,
        refetch: refetchVeterinariData,
    } = useGetVeterinariQuery(districtId, {
        skip: !districtId
    });

    const {
        data: distrettiData,
        error: errorDistretti,
        refetch: refetchDistrettiData,
    } = useGetDistrictsByUserQuery({user_id: sessionData?.user_id})

    useEffect(() => {
        if(sessionData && distrettiData) {
            formikDistretti.setFieldValue("distretto", {value: sessionData.district_id, label: distrettiData.find(x => x.id == sessionData.district_id).descrizione});
        }
    }, [distrettiData])

    useEffect(() => {
        if (errorVeterinari) {
            showToast("danger", "Errore", "Errore nel caricamento dei veterinari.");
        }
    }, [errorVeterinari]);

    const handleDateSelect = (arg: any) => {
        if (formatDate(arg.start) === formatDate(arg.end)) {
            setDateStartSelected(arg.start);
            setDateEndSelected(arg.end);
        } else {
            // Deseleziona l'intervallo
            arg.view.calendar.unselect();
        }
    };

    const formikVeterinario = useFormik({
        initialValues: {
            veterinario: {
                value: "",
                label: ""
            },
        },
        validationSchema: Yup.object({
            tipo_prestazione: Yup.mixed(),
        }),
        onSubmit: (values) => {
        }
    });

    const formikDistretti = useFormik({
        initialValues: {
            distretto: {
                value: "",
                label: "",
            },
        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            distretto: Yup.mixed(),
        }),
        onSubmit: (values) => {}
    })

    function onChangeVeterinario(change: any) {
        formikVeterinario.setFieldValue("veterinario", change);
        setVetId(change.value);
    }

    const onChangeDistretto = async (change: any) => {
        console.log(change);
        formikDistretti.setFieldValue("distretto", change);
        setDistrictId(change.value);
        refetchLogsData();
        const changedistrict = await setDistrict({district: Number(change.value)}).unwrap();

    }

    function unSelect() {
        if (refCalendar.current) {
            const calendarApi = refCalendar.current.getApi();
            calendarApi.unselect();
        }
    }


    /* ###########   🐾 Timeline Veterinario  ########### */

    const [dateStartSelected, setDateStartSelected] = useState<string>();
    const [dateEndSelected, setDateEndSelected] = useState<string>();
    const [serviceSelected, setServiceSelected] = useState<Service>();
    const [userSelected, setUserSelected] = useState<User>();

    // Visualizzare tutti gli eventi di un veterinario del giorno selezionato
    const {
        data: veterinaryDayBookingsData,
        error: errorVeterinaryDayBookingData,
        isLoading: isLoadingVeterinaryDayBookingData,
        refetch: refetchVeterinaryDayBookingData,
    } = useGetVeterinaryDayBookingsQuery({
        vet_id: vetId,
        start_time: dateStartSelected ? formatDate(dateStartSelected) : "",
    }, {
        skip: !dateStartSelected || !vetId
    });

    useEffect(() => {
        if (errorVeterinaryDayBookingData) {
            showToast("danger", "Errore", "Errore nel caricamento delle attività giornaliere del veterinario.");
        }
    }, [errorVeterinaryDayBookingData]);

    // Prendi tutti gli eventi del veterinario selezionasto per metterli occupati nel calendario
    const {
        data: eventByVetIdData,
        error: errorEventByVetIdDataData,
        isLoading: isLoadinEventByVetIdDataData,
    } = useGetEventByVetIdQuery({
            vet_id: vetId,
        }, {
            skip: !vetId
        }
    );

    useEffect(() => {
        if (errorEventByVetIdDataData) {
            showToast("danger", "Errore", "Errore nel caricamento degli eventi del veterinario.");
        }
    }, [errorEventByVetIdDataData]);

    // Stati per gestire l'evento selezionato e il modale
    const [selectedEvent, setSelectedEvent] = useState<any>(null);
    const [showEventModal, setShowEventModal] = useState<boolean>(false);

    // Funzione per gestire il click su un evento
    const handleEventClick = (clickInfo: any) => {
        // Otteniamo l'ID dell'evento cliccato
        const eventId = clickInfo.event.id;
        // Troviamo i dati completi dell'evento
        const eventData = eventByVetIdData?.find(event => event.id === eventId);
        
        if (eventData) {
            // Impostiamo l'evento selezionato e mostriamo il modale
            setSelectedEvent(eventData);
            setShowEventModal(true);
        }
    };

    // Aggiungiamo la query per ottenere le prestazioni
    const { data: calendarPrestazioniData } = useGetPrestazioniQuery({
        reddito: true,
        tipo: ""
    });

    // Funzione per ottenere la descrizione della prestazione dall'ID
    const getPrestazioneDescrizione = (prestazioneId: string) => {
        if (!calendarPrestazioniData) return "Evento";
        const prestazione = calendarPrestazioniData.find(p => p.id === prestazioneId);
        return prestazione ? prestazione.descrizione : "Evento";
    };

    // Aggiorniamo la mappatura degli eventi per usare la descrizione della prestazione come titolo
    useEffect(() => {
        if (vetId && eventByVetIdData) {
            const newEvents = eventByVetIdData.map((evt) => ({
                id: evt.id,                           
                start: evt.booking_date_time_start,
                end: evt.booking_date_time_end,
                //title: evt.tipo_prestazione ? getPrestazioneDescrizione(evt.tipo_prestazione) : 'Evento',
                display: 'block',               
                backgroundColor: '#f8d7da',
                borderColor: '#dc3545',
                textColor: '#721c24'
            }));
            setEvents(newEvents);
        }
    }, [vetId, eventByVetIdData, calendarPrestazioniData]);


    useEffect(() => {
        if (vetId && dateStartSelected) {
            refetchVeterinaryDayBookingData();
        }
    }, [vetId, dateStartSelected]);


    /* ###########   📊 Tab Attività - Logs  ########### */

    const { data: logsData, error: errorLogs, refetch: refetchLogsData} = useGetLogsQuery(districtId,
    {
        skip: !districtId
    });

    useEffect(() => {
        if (errorLogs) {
            showToast("danger", "Errore", "Errore nel caricamento delle attività.");
        }
    }, [errorLogs]);

    useEffect(() => {
        if (districtId) {
            refetchServicesToAssignData();
            refetchLogsData();
            refetchVeterinariData();
            formikVeterinario.resetForm();
            setServiceSelected(servicesToAssignData ? servicesToAssignData[0] : undefined);
        }
    }, [districtId]);


    /* ###########   🪟 Modale creazione nuova prestazione  ########### */

    const [newServiceId, setNewServiceId] = useState<number | null>();
    const [slickGoToIndex, setSlickGoToIndex] = useState<number>(0);
    const [modalNewServiceShow, setModalNewServiceShow] = useState(false);
    const [createDistrictService, { isLoading: isLoadingCreateDistrictService, isSuccess: isSuccessDistrictService,  error: errorDistrictService }] = useCreateDistrictServiceMutation();

    useEffect(() => {
        if (errorDistrictService) {
            showToast("danger", "Errore", "Errore nella creazione della prestazione.");
        }
    }, [errorDistrictService]);

    const formikNewService = useFormik({
        initialValues: {
            tipo_prestazione: null,
            azienda: [],
            animali_per_prenotazione: 1,
            notes: "",
            email: "",
            telefono: "",
        },
        validationSchema: Yup.object({
            tipo_prestazione: Yup.mixed().required(""),
            azienda: Yup.mixed().required(""),
            animali_per_prenotazione: Yup.number().required(""),
            notes: Yup.string(),
            email: Yup.string().email().required(""),
            telefono: Yup.string().required(""),
        }),
        onSubmit: (values: any) => {
            const newValues = {...values}
            newValues["cod_azienda"] = values.azienda[0].cod_azienda;
            newValues["citta"] = values.azienda[0].comune;
            newValues["sottocod_azienda"] = values.azienda[0].sottocod_azienda;
            newValues["ragione"] = values.azienda[0].ragione;
            newValues["indirizzo"] = values.azienda[0].indirizzo;
            delete newValues.azienda;
            createService(cleanJSON(newValues));

        }
    });

    const createService = async (values: any) => {

        try {
            const res = await createDistrictService(values).unwrap();
            if (res.id) {
                setNewServiceId(res.id);
                await refetchServicesToAssignData();
            }
            formikNewService.resetForm();
            setModalNewServiceShow(false);
            setDateStartSelected("");
            setDateEndSelected("");

            setUserSelected(undefined);
            setServiceSelected(undefined);
            setActiveCardIndex(null);

            showToast("success", "Successo", "Prestazione creata con successo.");

        } catch (error) {
            console.error('Errore nella creazione del post:', error);
        }
    }


    /* ###########   🪟 Modale Summary  ########### */

    const [modalSummaryShow, setModalSummaryShow] = useState(false);
    const [createAssignBooking, { isLoading: isLoadingCreateAssignBooking, isSuccess: isSuccessCreateAssignBooking,error: errorCreateAssignBooking }] = useCreateAssignBookingMutation();

    useEffect(() => {
        if (errorCreateAssignBooking) {
            showToast("danger", "Errore", "Errore nell'assegnazione della prenotazione.");
        }
    }, [errorCreateAssignBooking]);

    function showServiceModal(service: Logs) {
        if (service.stato === "to_be_assigned"){
            return;
        }
        const showService: any = {
            ...service, // Spread all existing properties from the original service
            tipo_prestazione: service.id_prestazione, // Map the id_prestazione to tipo_prestazione
            comune: service.id_comune,               // Map the id_comune to comune
        };
        setServiceSelected(showService);
        if (service.booking_date_time_start){
            setDateStartSelected(service.booking_date_time_start);
        }
        if (service.booking_date_time_end){
            setDateEndSelected(service.booking_date_time_end);
        }
        const user = {
            id: service.user_id,
            name: service.name,
            surname: service.surname,
            appointments: service.appointments
        }
        if (user){
            setUserSelected(user);
        }
        setModalSummaryShow(true);
    }

    const assignBooking = async () => {
        try {

            if (!serviceSelected?.id || !userSelected?.id || !dateStartSelected || !dateEndSelected) {
                throw new Error('Missing required data: service, user, or date.');
            }

            const localStartDate = new Date(dateStartSelected);
            const localStartDateFormatted = formatDateTime(new Date(localStartDate));

            const localEndDate = new Date(dateEndSelected);
            const localEndDateFormatted = formatDateTime(new Date(localEndDate));

            const values = {
                booking_id: serviceSelected.id,
                user_id: userSelected.id,
                booking_date_time_start: localStartDateFormatted,
                booking_date_time_end: localEndDateFormatted,
            };

            await createAssignBooking(values).unwrap();
            unSelect();
            setModalSummaryShow(false);
            showToast("success", "Successo", "Prenotazione assegnata con successo.");
            refetchServicesToAssignData();
            refetchLogsData();
            setDateStartSelected("");
            setDateEndSelected("");
            setUserSelected(undefined);
            setServiceSelected(undefined);
            setActiveCardIndex(null);
            setVetId(null);
            formikVeterinario.resetForm();
        } catch (error) {
            console.error('Error assigning booking:', error);
        }
    };

    function confirmAppointment(user: User) {
        setUserSelected(user);
        setModalSummaryShow(true);
    }

    return (
            <div className="container">{!isLoading ?
                <div>
                    <div className="d-flex flex-column flex-md-row justify-content-between gap-0 gap-md-5">
                        <div>
                            <h1>Attività animali da reddito</h1>
                            <p>Scegli la tua prestazione e compila tutti i campi per la creazione della prenotazione del
                                tuo appuntamento. Se hai bisogno di assistenza, contatta lo sportello selezionato.
                                Forniamo un servizio clienti dedicato per rendere il processo di prenotazione semplice
                                ed efficiente.</p>
                        </div>
                        <div style={{marginTop: "50px", whiteSpace: "nowrap"}}>
                            <Button className="bg-white" variant="outline-primary fw-bold"
                                    onClick={goToCalendarPets}>Calendario Attività per animali d'affezione</Button>
                            <div className="mt-4">
                                {distrettiData && sessionData && <FormSelect name="Distretto" formik={formikDistretti}
                                                                             label="Distretto"
                                                                             onChange={onChangeDistretto}
                                                                             bold={true}
                                                                             value={formikDistretti.values.distretto}
                                                                             options={distrettiData ? distrettiData : []}></FormSelect>}
                            </div>
                        </div>
                    </div>

                    <Tabs
                        activeKey={eventKey}
                        transition={false}
                        id="noanim-tab-example"
                        className="mb-3 mt-5"
                        onSelect={(k: any) => selectTab(k)}
                    >
                        <Tab eventKey="service_to_assign" title="Attività da assegnare">
                            <div className="mt-4">
                                <div
                                    className="d-flex flex-column flex-md-row align-items-start align-items-md-center gap-2 gap-md-3 mb-5 mb-md-0">
                                    <div className="d-flex align-items-center gap-3">
                                        <h2 className="fw-bold mb-0">Seleziona attività</h2>
                                        {(isLoadingServicesToAssign || isLoadingPrestazioni || !servicesToAssignData) &&
                                            <Spinner animation="border" role="status" variant="primary">
                                                <span className="visually-hidden">Loading...</span>
                                            </Spinner>}
                                    </div>
                                    <div style={{whiteSpace: "nowrap"}}>
                                        <Button className="bg-white" variant="outline-primary fw-bold"
                                                onClick={() => setModalNewServiceShow(true)}>Inserimento attività
                                            manuale</Button>
                                    </div>
                                </div>

                                <div className="my-4 services-container" aria-hidden="true">

                                    {servicesToAssignData && servicesToAssignData.length == 0 &&
                                        <div>Non ci sono prestazioni da assegnare</div>}

                                    <div
                                        className={servicesToAssignData && servicesToAssignData.length < 3 ? "min-3-element" : ""}>

                                        <SliderComponent slideShow={3} slidesToScroll={3}
                                                         slickGoToIndex={slickGoToIndex}>

                                            {prestazioniData && servicesToAssignData && servicesToAssignData.map((service, index) => (
                                                <div key={index}>
                                                    <div
                                                        className={`card-container ${activeCardIndex === index ? 'active' : ''}`}
                                                        title={prestazioniData && prestazioniData.filter(x => x.id == service.tipo_prestazione)[0]?.descrizione}
                                                        onClick={() => handlePrestazioneSelect(index)}>
                                                        <h2 className="fw-bold">{prestazioniData && prestazioniData.filter(x => x.id == service.tipo_prestazione)[0]?.descrizione}</h2>
                                                        <p>Animali per prenotazione: {service.quantita}</p>

                                                        <hr/>

                                                        <div className="d-flex flex-row gap-3 my-2">
                                                            <div className="fs-5 text-primary">
                                                                <FontAwesomeIcon icon={faLocationDot}/>
                                                            </div>
                                                            <div>
                                                                <div className="fw-bold">{service.ragione}</div>
                                                                <div>{service.indirizzo}, ({service.citta})</div>
                                                            </div>
                                                        </div>

                                                        {service.booking_date_time_start && <>
                                                            <div className="d-flex flex-row gap-3 align-items-center">
                                                                <div
                                                                    className="d-flex flex-row gap-3 align-items-center">
                                                                    <div className="fs-5 text-primary">
                                                                        <FontAwesomeIcon icon={faCalendar}/>
                                                                    </div>
                                                                    <div>{format(new Date(service.booking_date_time_start), 'dd/MM/yyyy')}</div>
                                                                </div>
                                                                <div
                                                                    className="d-flex flex-row gap-3 align-items-center">
                                                                    <div className="fs-5 text-primary">
                                                                        <FontAwesomeIcon icon={faClock}/>
                                                                    </div>
                                                                    <div>{service && service.booking_date_time_start && new Date(service.booking_date_time_start).toLocaleTimeString([], {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit',
                                                                        hour12: false
                                                                    })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>}

                                                        {service.booking_notes && <div>
                                                            <hr/>

                                                            {service.booking_notes}

                                                        </div>}
                                                    </div>
                                                </div>
                                            ))}

                                            {(!prestazioniData || !servicesToAssignData) &&
                                                <div className="card-container skeleton animate-pulse"></div>}
                                            {(!prestazioniData || !servicesToAssignData) &&
                                                <div className="card-container skeleton animate-pulse"></div>}
                                            {(!prestazioniData || !servicesToAssignData) &&
                                                <div className="card-container skeleton animate-pulse"></div>}

                                        </SliderComponent>

                                    </div>
                                </div>

                                <div className="row">

                                    <div className="mb-100 col-sm-9">

                                        <div className="d-flex align-items-center gap-3">
                                            <h2 className="my-3 fw-bold">
                                                Calendario
                                            </h2>

                                            {isLoadinEventByVetIdDataData &&
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>}
                                        </div>


                                        <div
                                            className={`p-4 bg-light-grey ${isLoadingPrestazioni || isLoadinEventByVetIdDataData || isLoadingServicesToAssign ? 'disabled-element' : ''}`}>

                                            <p>
                                                Seleziona un veterinario, quindi clicca e trascina per definire
                                                l'intervallo di tempo per la prestazione. Gli elementi evidenziati in
                                                rosso indicano che il veterinario è già impegnato in quel periodo.
                                            </p>

                                            <div className="row mb-5">
                                                <div className="col-md-6">
                                                    <FormSelect name="veterinario" formik={formikVeterinario}
                                                                label="Veterinario"
                                                                onChange={onChangeVeterinario}
                                                                value={formikVeterinario.values.veterinario}
                                                                options={veterinariData ? veterinariData : []}></FormSelect>
                                                </div>
                                            </div>

                                            <div className={vetId ? "" : "disabled-element"}>
                                                <FullCalendar
                                                    ref={refCalendar}
                                                    plugins={[timeGridPlugin, interactionPlugin]}
                                                    initialView={isMobile ? "timeGridDay" : "timeGridWeek"}
                                                    slotLabelInterval="01:00"
                                                    slotMinTime={smallestStartTime}
                                                    slotMaxTime={largestEndTime}
                                                    selectable={true}
                                                    slotDuration={formatMinutesToTime(slot)}
                                                    select={handleDateSelect}
                                                    expandRows={true}
                                                    editable={false}
                                                    initialDate={dateStartSelected}
                                                    validRange={validRange}
                                                    weekends={true}
                                                    locale="it"
                                                    handleWindowResize={true}
                                                    height="600px"
                                                    allDaySlot={false}
                                                    events={events}
                                                    slotEventOverlap={false}
                                                    eventOverlap={false}
                                                    businessHours={businessHours}
                                                    headerToolbar={{
                                                        left: 'title',
                                                        right: 'prev,next',
                                                    }}
                                                    selectOverlap={false}
                                                    selectMirror={true}
                                                    unselectAuto={false}
                                                    selectLongPressDelay={0}
                                                    longPressDelay={0}
                                                    eventClick={handleEventClick}

                                                />
                                            </div>


                                            {dateStartSelected && dateEndSelected && <>
                                                <h2 className="my-3 pt-4">
                                                    Prenotazione Selezionata:
                                                </h2>

                                                <div className="d-flex flex-column flex-md-row gap-3">
                                                    <div className="bg-secondary p-4 border-1 border-primary flex-fill"
                                                         style={{borderStyle: 'solid'}}>
                                                        <div className="d-flex flex-row gap-3 align-items-center">
                                                            <div className="fs-3 text-primary">
                                                                <FontAwesomeIcon icon={faCalendar}/>
                                                            </div>
                                                            <div className="fw-bold text-uppercase text-black">
                                                                {format(new Date(dateStartSelected), 'dd/MM/yyyy')}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="bg-secondary p-4 border-1 border-primary flex-fill"
                                                         style={{borderStyle: 'solid'}}>
                                                        <div className="d-flex flex-row gap-3 align-items-center">
                                                            <div className="fs-3 text-primary">
                                                                <FontAwesomeIcon icon={faClock}/>
                                                            </div>
                                                            <div className="fw-bold text-uppercase text-black">
                                                                {new Date(dateStartSelected).toLocaleTimeString([], {
                                                                    hour: '2-digit',
                                                                    minute: '2-digit',
                                                                    hour12: false
                                                                })}
                                                                - {new Date(dateEndSelected).toLocaleTimeString([], {
                                                                hour: '2-digit',
                                                                minute: '2-digit',
                                                                hour12: false
                                                            })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </>}
                                        </div>


                                    </div>

                                    <div className="mb-100 col-sm-3 mt-5 mt-md-0 veterinari-container">

                                        <div className="d-flex align-items-center gap-3">
                                            <h2 className="my-3 fw-bold">
                                                Attività giornaliere
                                            </h2>
                                            {isLoadingVeterinaryDayBookingData &&
                                                <Spinner animation="border" role="status" variant="primary">
                                                    <span className="visually-hidden">Loading...</span>
                                                </Spinner>}
                                        </div>


                                        {dateStartSelected && dateEndSelected && veterinaryDayBookingsData &&
                                            <div className={`p-4 bg-light-grey ${vetId ? "" : "disabled-element"}`}>
                                                {serviceSelected && <UserTimeline user={veterinaryDayBookingsData}
                                                                                  selectedStartDate={new Date(dateStartSelected)}
                                                                                  selectedEndDate={new Date(dateEndSelected)}
                                                                                  comuneId={serviceSelected.comune}
                                                                                  confirmAppointment={confirmAppointment}
                                                ></UserTimeline>}
                                            </div>
                                        }

                                        {!dateStartSelected &&
                                            <div className={`p-4 bg-light-grey ${vetId ? "" : "disabled-element"}`}>
                                                Seleziona una data per visualizzare le attitvità della giornata per il
                                                veterinario selezionato.
                                            </div>}
                                    </div>

                                </div>
                            </div>

                            {districtId && <ModalNewService modalShow={modalNewServiceShow}
                                                            setModalShow={setModalNewServiceShow}
                                                            formik={formikNewService}
                                                            districtId={districtId}
                                                            isLoading={isLoadingCreateDistrictService}
                            ></ModalNewService>
                            }

                        </Tab>

                        <Tab eventKey="all_service" title="Attività">

                            <Table responsive className="table-design mt-2">
                                <thead>
                                <tr>
                                    <th>Prestazione</th>
                                    <th>Data</th>
                                    <th>Comune</th>
                                    <th>Veterinario</th>
                                    <th>Stato</th>
                                </tr>
                                </thead>
                                <tbody>

                                {logsData && logsData.length === 0 && <div>Non ci sono attività</div>}

                                {logsData && logsData.map((service, index) => (
                                    <tr key={index} onClick={() => showServiceModal(service)}>
                                        <td className="fw-bold" title={service.descrizione}>
                                            <div className="table-prestazione">
                                                {service.descrizione}
                                            </div>

                                        </td>
                                        <td>
                                            {service.booking_date_time_start ? new Date(service.booking_date_time_start).toLocaleDateString('it-IT', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric'
                                            }) : "-"}
                                        </td>
                                        <td>{service.comune}</td>
                                        <td>
                                            {service.name ? <AvatarUser user={{
                                                name: service.name,
                                                surname: service.surname
                                            }}></AvatarUser> : "-"}
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row gap-2 align-items-center">
                                                <div className={`status ${service.stato}`}></div>
                                                <div
                                                    className="text-uppercase">{Status && service.stato && Status[service.stato]}</div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}

                                </tbody>
                            </Table>
                        </Tab>
                    </Tabs>

                    {userSelected && dateStartSelected && dateEndSelected && serviceSelected &&
                        <ModalSummary slot={slot} isLoading={isLoadingCreateAssignBooking} modalShow={modalSummaryShow}
                                      setModalShow={setModalSummaryShow} userSelected={userSelected}
                                      selectedStartDate={new Date(dateStartSelected)}
                                      selectedEndDate={new Date(dateEndSelected)} serviceSelected={serviceSelected}
                                      assignBooking={assignBooking}></ModalSummary>}

                    {/* componente EventDetailsModal */}
                    <EventDetailsModal 
                        event={selectedEvent} 
                        show={showEventModal} 
                        onHide={() => setShowEventModal(false)} 
                    />

                </div> :
                <Spinner animation="border" role="status" variant="primary" className="mt-5">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>}

            </div>
        );
};

export default FarmAnimalActivities;
